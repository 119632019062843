<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col
          v-if="userType !== 3"
          cols="5"
        >
          <div class="lblTitle">
            <h3>{{ $t('messages.factoryName') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-autocomplete
              v-model="customer"
              :items="customers"
              :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
              item-value="id"
              :no-data-text="$t('table.messages.no_data')"
              clearable
              dense
              single-line
              outlined
            />
          </div>
        </v-col>
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t('messages.shiftPeriod') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="dates"
                  multiple
                  outlined
                  clearable
                  dense
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 5">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 5"
                      class="grey--text text-caption"
                    >(+{{ dates.length - 5 }} その他)</span>
                  </template>
                </v-combobox>
              </template>
              <v-date-picker
                v-model="dates"
                multiple
                no-title
                dense
                scrollable
                locale="ja"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates)"
                >
                  {{ $t('messages.ok') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{ $t('messages.cancel') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col
          v-if="userType === 3"
          cols="5"
        >
          <div class="lblTitle">
            <h3>状態</h3>
          </div>
          <div
            class="align-self-center mr-9"
          >
            <v-select
              v-model="isSubmitted"
              :items="statusSubmit"
              item-text="text"
              item-value="value"
              outlined
              dense
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
          <div
            v-if="userType !== 3"
            class="text-button ml-2"
          >
            {{ profile.name ? profile.name : '' }}
          </div>
        </v-col>
        <v-col
          cols="2"
          class="d-flex"
        >
          <div class="align-self-center mr-9">
            <v-btn
              color="forth"
              class="pt-0 pl-10 pr-10 white--text d-flex justify-star"
              @click="onSearch()"
            >
              <span class="font-weight-bold text-h5">
                {{ $t('messages.display') }}
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row class="d-flex justify-end">
            <v-col cols="5">
              <div class="align-self-center">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0"
                  :placeholder="$t('placeholder.shiftList')"
                  outlined
                  clearable
                  dense
                  @keydown.enter="onSearch()"
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="mb-9">
                <v-btn
                  color="primary"
                  @click="onSearch()"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.search') }}
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            :items-per-page="10"
            :server-items-length="totalItems"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:footer.page-text
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:item.index="props">
              {{ getIndex(props.index) }}
            </template>

            <template v-slot:item.name="props">
              <span
                class="font-weight-bold textName"
                @click="getDetailPeriodShift(getValueObject(props.item, 'id'), getValueObject(props.item, 'customer.user.name'))"
              >
                {{ getValueObject(props.item, 'name') }}
              </span>
            </template>

            <template v-slot:item.factoryName="props">
              {{ getValueObject(props.item,'customer.user.name') }}
            </template>
            <template v-slot:item.job="props">
              {{ getValueObject(props.item, 'jobNames') }}
            </template>

            <template v-slot:item.timeShift="props">
              {{ convertTime(getValueObject(props.item, 'updatedAt'), 'YYYY/MM/DD HH:mm') }}
            </template>

            <template v-slot:item.status="props">
              <span
                v-if="props.item.signature"
                class="textName font-weight-bold"
              >
                署名済み
              </span>
            </template>
            <template v-slot:item.action="props">
              <span
                class="error--text font-weight-bold"
                style="cursor: pointer"
                @click="deleteItem(props.item)"
              >
                {{ $t('messages.delete') }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <dialog-delete-shift
      :dialog-delete-shift="dialogDelete"
      @removeShift="deletePeriod(getValueObject(selectedItem, 'id'))"
      @closeDialogDelete="closeDelete"
    />
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import DialogDeleteShift from '../../components/DialogDeleteShift'
  import { convertTime } from '../../util/covertTime'

  export default {
    name: 'ShiftList',
    components: {
      DialogDeleteShift,
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        dialogDelete: false,
        itemPaginate: [],
        items: [],
        search: '',
        totalItems: 0,
        totalStt: 0,
        pageOld: 0,
        countOld: 0,
        showPickerStartAt: false,
        showPickerEndAt: false,
        showPickerStartAtEdit: false,
        showPickerEndAtEdit: false,
        headers: [],
        mainHeaders: [],
        options: {},
        editMode: false,
        loading: false,
        snackbarMessage: '',
        snackbar: false,
        job: null,
        isSubmitted: '',
        dates: [],
        planStartAt: '',
        planEndAt: '',
        menu: false,
        menuPickerStartAt: false,
        menuPickerEndAt: false,
        userType: 1,
        selectedItem: {},
        statusSubmit: [
          {
            value: '',
            text: 'すべて',
          },
          {
            value: 1,
            text: '出席',
          },
          {
            value: 2,
            text: '末送信',
          },
        ],
        customers: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        customer: '',
      }
    },
    computed: {
      ...get('timesheet', ['message', 'status', 'error', 'listTimesheet']),
      customerList: get('customer@list.data.customers'),
      ...get('user', ['profile']),
    },
    watch: {
      options: {
        handler () {
          this.initialize()
          if (this.pageOld !== 0) {
            if (this.options.page > this.pageOld && this.options.page !== 1) {
              this.totalStt += this.items.length
            } else if (this.options.page < this.pageOld && this.options.page !== 1) {
              this.totalStt -= this.countOld
            } else {
              this.totalStt = 0
            }
          }
          this.pageOld = this.options.page
          this.countOld = this.items.length
        },
        deep: true,
      },
      profile (value) {
        if (value) {
          this.userType = value.type
          if (value.type !== 3) {
            this.getCustomers()
          }
        }
      },
      listTimesheet (value) {
        if (this.userType !== 4) {
          if (value.data.count <= this.options.itemsPerPage && this.options.page !== 1) {
            this.options.page = 1
            return
          }
          this.totalItems = value.data.count
          this.$set(this, 'items', value.data.timesheets)
        } else {
          this.getItemsForUserType(value.data.timesheets)
        }
      },
      message (value) {
        if (value === 'success') {
          this.snackbarMessage('messages.success')
        } else if (value === 'deleteSuccess') {
          this.showSnackBar('messages.deleteSuccess')
          this.initialize()
        }
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      customerList (value) {
        value.forEach(item => {
          this.customers.push(item)
        })
      },
      dates (value) {
        if (value.length === 0) {
          this.items = []
        }
        this.initialize()
      },
      userType (value) {
        if (value === 3) {
          this.customer = JSON.parse(
            JSON.parse(localStorage.getItem('user')).data,
          ).typeCorrespondId
          this.initialize()
        } else {
          this.getCustomers()
        }
        this.getHeaders()
      },
      customer () {
        this.initialize()
      },
      isSubmitted () {
        this.initialize()
      },
      search (value) {
        if (!value) {
          this.initialize()
        }
      },
    },
    created () {
      this.$store.dispatch('user/getProfile')
      this.initialize()
    },
    mounted () {
      this.getHeaders()
      if (this.$route.query) {
        if (this.$route.query.success) {
          this.showSnackBar('messages.success')
        } else if (this.$route.query.deleteSuccess) {
          this.showSnackBar('messages.deleteSuccess')
        } else if (this.$route.query.updateSuccess) {
          this.showSnackBar('messages.updateSuccess')
        }
      }
    },
    methods: {
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getItemsForUserType (items) {
        this.items = items.filter(item => item.submittedAt)
      },
      getHeaders () {
        if (this.userType === 3) {
          this.headers = [
            {
              text: this.$t('table.headers.index'),
              align: 'start',
              value: 'index',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.shiftName'),
              align: 'center',
              value: 'name',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.job'),
              align: 'center',
              value: 'job',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.updatedTime'),
              align: 'center',
              value: 'timeShift',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.status'),
              align: 'center',
              value: 'status',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
          ]
        } else {
          this.headers = [
            {
              text: this.$t('table.headers.index'),
              align: 'start',
              value: 'index',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.shiftName'),
              align: 'center',
              value: 'name',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.factoryName'),
              align: 'center',
              value: 'factoryName',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.job'),
              align: 'center',
              value: 'job',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.updatedTime'),
              align: 'center',
              value: 'timeShift',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.status'),
              align: 'center',
              value: 'status',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
            {
              text: this.$t('table.headers.action'),
              align: 'center',
              value: 'action',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
            },
          ]
        }
      },
      getCustomers () {
        this.$store.dispatch('customer/getCustomers')
      },
      deleteItem (item) {
        this.dialogDelete = !this.dialogDelete
        this.selectedItem = item
      },
      deletePeriod (timesheetId) {
        this.$store.dispatch('timesheet/deletePeriod', timesheetId)
      },
      closeDelete () {
        this.dialogDelete = false
      },
      convertTime,
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      getDetailPeriodShift (timesheetId, nameCustomer) {
        const payload = {
          timesheetId: timesheetId,
          nameCustomer: nameCustomer,
        }
        this.$router.push({ path: '/detail-time-sheet', query: payload })
      },
      initialize () {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage ? parseInt(tmpOptions.itemsPerPage) : 10
        tmpOptions.page = tmpOptions.page ? parseInt(tmpOptions.page) : 1
        if (this.dates.length > 0) {
          tmpOptions.timestamps = JSON.stringify(this.dates)
        }
        if (this.search) {
          tmpOptions.keyword = this.search
        }
        if (this.customer) {
          tmpOptions.customerId = this.customer
        }
        if (this.isSubmitted) {
          tmpOptions.isSubmitted = this.isSubmitted
        }
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('timesheet/getAttendanceTimeSheets', tmpOptions)
      },
      close () {
        this.dialog = false
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = ''
        })
      },
      onSearch () {
        if (this.options.page !== 1) {
          this.options.page = 1
        } else {
          this.initialize()
        }
      },
      paginate (array, pageSize, pageNumber) {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
    },
  }
</script>

<style scoped lang="sass">
  .border-right
    border-right: thin solid rgba(0, 0, 0, 0.12) !important

  .shiftWarp
    margin-top: 15px
    padding: 0 50px

    .infoWarp
      padding-top: 20px

    .lblText
      margin-top: 10px

    .lblTitle
      color: #5CA6D2

  .dateWarp
    display: flex
    justify-content: space-between

    .icon-fall
      font-size: 20px
      font-weight: bold
      margin-top: 10px

  .titleFlex
    display: flex
    align-items: center

  .btnFlex
    display: flex
    align-items: center

    button
      margin: 0px !important

  .shiftAt
    margin-left: 0px !important
  .v-dialog
    overflow-y: hidden!important
  .lblText
    color: #25ACD8
  .borderDialog
    margin-top: 24px
    border: 1px solid #25ACD8
    border-radius: 5px
    padding: 10px
  .iconSuccess__lange
    font-size: 70px
  .textName
    cursor: pointer
    color: #37CCC5
  .fs20
    font-size: 20px
</style>
